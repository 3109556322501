<template>
  <div>
    <el-select style="width: 100%" v-model="year"  @change="selectStaByYear" placeholder="请选择复审年份">
      <el-option
          v-for="item in years"
          :key="item"
          :label="item"
          :value="item">
      </el-option>
    </el-select>
    <div style="margin-top: 40px">
      <div style="width: 50%; height: 100%; display: inline-block; float: left;">
        <el-table
            :data="reviewCountByOrg"
            stripe
            style="width: 90%">
          <el-table-column
              prop="org"
              label="市州"
              width="150">
          </el-table-column>
          <el-table-column
              prop="jzg"
              label="架子工数"
              width="150">
          </el-table-column>
          <el-table-column
              prop="qzg"
              label="起重工数"
              width="150">
          </el-table-column>
          <el-table-column
              prop="小计"
              label="小计">
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 50%; height: 100%; display: inline-block; margin-left: 0; float: left;">
        <el-table
            :data="reviewCountByMonth"
            stripe
            style="width: 90%; float: right">
          <el-table-column
              prop="yue"
              label="月份"
              width="150">
          </el-table-column>
          <el-table-column
              prop="jzg"
              label="架子工数"
              width="150">
          </el-table-column>
          <el-table-column
              prop="qzg"
              label="起重工数">
          </el-table-column>
          <el-table-column
              prop="小计"
              label="小计"
              width="150">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>

import request from "@/util/request";
let v = 123;
let a = "123456" + v + "123456";

export default {
  name: "Index",
  data() {
    return {
      year: "",
      reviewCountByMonth: [],
      reviewCountByOrg: [],
      years: [],

    }
  },
  methods: {
    selectStaByYear() {
      let message = this.$message;
      request({
        url: "/manager/getReviewSta.do",
        method: "GET",
        params: {
          year: this.year
        }
      }, res => {
        if (res.code === 200) {
          this.reviewCountByMonth = res.data.reviewCountByMonth;
          this.reviewCountByOrg = res.data.reviewCountByOrg;
          message.success("查询成功");
        }
      }, err => {
      }, this.$store.getters.token());
    }
  },
  beforeCreate() {
    request({
      url: "/manager/getReviewSta.do",
      method: "GET",
    }, res => {
      if (res.code === 200) {
        this.reviewCountByMonth = res.data.reviewCountByMonth;
        this.reviewCountByOrg = res.data.reviewCountByOrg;
        this.years = res.data.years;
        this.year = this.years[this.years.length -1]
      }
    }, err => {
    }, this.$store.getters.token());

  }
}
</script>

<style scoped>

</style>